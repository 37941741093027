.config {
  text-align: center;
}

.header {
  $font: "Poppins", sans-serif;

  text-transform: uppercase;
  background: linear-gradient(to right, #30cfd0 0%, #330867 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font: {
    size: 10vw;
    family: $font;
  }
}

.config-form {
  margin: 24px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.player {
  display: flex;
  margin: 8px auto;
}

.player-input {
  font-size: 20px;
  padding: 8px;
}

.player-remove {
  background-color: #9b2335;
  color: white;
  width: 40px;
}

.player-remove-disabled {
  background-color: grey;
}

.config-actions {
  display: inline-flex;
  gap: 20px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
}

.stick-config {
  margin: 16px auto;
}

.custom-config {
  max-width: 500px;
  margin: 24px;
}
