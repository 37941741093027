.bn39 {
  background-image: linear-gradient(135deg, #008aff, #86d472);
  border-radius: 6px;
  box-sizing: border-box;
  color: #ffffff;
  display: block;
  font-size: 1.4em;
  font-weight: 600;
  padding: 8px;
  position: relative;
  text-decoration: none;
  z-index: 2;
  user-select: none;
}

.bn39:hover {
  color: #fff;
}

.bn39-disabled {
  background-image: inherit;
  background-color: grey;
}

.bn39 .bn39span {
  align-items: center;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}
