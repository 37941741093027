.blood {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

$blood-color: #9f0000;

$multiple-width: 3;
$multiple-anim: 0.5;

.blood-item {
  width: $multiple-width * 3vmax;
  height: 0;
  position: relative;
  box-sizing: border-box;
  margin: 0;
  float: left;
  border-radius: 0 0 10vmax 10vmax;
  margin-top: -2vmin;
  background: $blood-color;
  animation: drop $multiple-anim * 5s cubic-bezier(0.34, 0.03, 0.76, 0.94) 0s;
  animation-fill-mode: forwards;
  box-shadow: 0 0 2px 1px $blood-color;
}

.blood-item:nth-child(2) {
  animation-delay: 0.5s;
  animation-duration: $multiple-anim * 6s;
  width: $multiple-width * 4vmax;
}

.blood-item:nth-child(3) {
  animation-delay: 0.7s;
  animation-duration: $multiple-anim * 4s;
  width: $multiple-width * 3.35vmax;
}

.blood-item:nth-child(4) {
  animation-delay: 0.75s;
  animation-duration: $multiple-anim * 6.17s;
}

.blood-item:nth-child(5) {
  animation-delay: 1.15s;
  animation-duration: $multiple-anim * 4.5s;
  width: $multiple-width * 4vmax;
}

.blood-item:nth-child(6) {
  animation-delay: 0.65s;
  animation-duration: $multiple-anim * 5.5s;
  width: $multiple-width * 3.35vmax;
}

.blood-item:nth-child(7) {
  animation-delay: 0.85s;
  animation-duration: $multiple-anim * 5.75s;
}

.blood-item:nth-child(8) {
  animation-delay: 1.05s;
  animation-duration: $multiple-anim * 5.16s;
}

.blood-item:nth-child(9) {
  animation-delay: 0.15s;
  animation-duration: $multiple-anim * 4.25s;
  width: $multiple-width * 4vmax;
}

.blood-item:nth-child(10) {
  animation-delay: 0.45s;
  animation-duration: $multiple-anim * 4.75s;
  width: $multiple-width * 3.35vmax;
}

@keyframes drop {
  0% {
    height: 0%;
  }
  100% {
    height: calc(100% + 6vmax);
  }
}

@media only screen and (orientation: portrait) {
  .blood-item:before {
    height: 3vmin;
  }
}
