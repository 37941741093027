.container {
  margin-top: 10vh;
  margin-right: 8px;
  margin-left: 8px;
}

.app {
  text-align: center;
  user-select: none;
}

.round {
  z-index: 1;
  position: relative;
}

$font: "Poppins", sans-serif;

$gradient-color1: #30cfd0;
$gradient-color2: #330867;
$nice-gradient: linear-gradient(
  to right,
  $gradient-color1 0%,
  $gradient-color2 100%
);

.round-player {
  user-select: none;
  text-transform: uppercase;
  background: $nice-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font: {
    size: 10vw;
    family: $font;
  }

  @media screen and (max-width: 500px) {
    font: {
      size: 20vw;
      family: $font;
    }
  }
}

.event-image {
  max-width: 200px;
  margin: 16px;
}

.event {
  z-index: 1;
  position: relative;
}

.event-card {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sticks {
  display: flex;
  gap: 10px;
  margin: 18px auto;
  user-select: none;
}

$picked-color: #fed766;
$chosen-color: #fe4a49;
$success-color: #2ab7ca;
$disabled-color: #808080;
$stick-color: white;
$color-blink-animation: 0.3s;

@keyframes blinkingBackgroundDisabled {
  0% {
    background-color: $picked-color;
  }
  100% {
    background-color: $disabled-color;
  }
}
@keyframes blinkingBackgroundPicked {
  0% {
    background-color: $picked-color;
  }
  100% {
    background-color: $stick-color;
  }
}

.stick {
  border-radius: 10px;
  padding: 16px 8px;
  display: inline-block;
  flex-grow: 1;
  background-color: $stick-color;
}

.sticks-clickable {
  cursor: pointer;
}

.stick-chosen {
  background-color: $chosen-color;
}
.stick-picked {
  animation: blinkingBackgroundPicked $color-blink-animation infinite;
}
.stick-success {
  background-color: $success-color;
}
.stick-disabled {
  background-color: $disabled-color;
  color: white;
}

.stick-disabled-picked {
  animation: blinkingBackgroundDisabled $color-blink-animation infinite;
}

.replay-actions {
  display: flex;
  gap: 24px;
  margin: 24px auto;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
}

.replay-button {
  margin: 16px;
  font-size: 30px;
}

.tips {
  position: fixed;
  bottom: 8px;
  left: 8px;
  z-index: 1;
}

.count-event {
  position: relative;
  border: 4px solid $gradient-color1;
  font-size: 20px;
  color: #333;
  padding: 17px;
  border-radius: 2px;
  border-radius: 3px;

  &::before {
    border-radius: 3px 0 0 3px;
    height: calc(100% + 2px);
    position: absolute;
    width: 8px;
    left: -1px;
    top: -1px;
    content: "";
    background: $gradient-color1;
  }
}
